(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@wix/image-kit"), require("react"), require("react-dom"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["imageClientApi", "react", "reactDOM", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["SideCartViewerWidgetNoCss"] = factory(require("@wix/image-kit"), require("react"), require("react-dom"), require("lodash"));
	else
		root["SideCartViewerWidgetNoCss"] = factory(root["__imageClientApi__"], root["React"], root["ReactDOM"], root["_"]);
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__7684__, __WEBPACK_EXTERNAL_MODULE__7762__, __WEBPACK_EXTERNAL_MODULE__8677__, __WEBPACK_EXTERNAL_MODULE__7607__) => {
return 